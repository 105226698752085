import { Helmet } from "react-helmet";
import { ContactIntro, ContactUs } from "../components";

export default function Contact() {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <ContactIntro />
      <ContactUs showHeading={false} id="contactForm"/>
    </div>
  )
}
