import { contactPhoto } from "../../assets";

const ContactIntro = () => {
  return (
    <div className="container mx-auto sm:px-16 px-6 my-20">
      <div className="flex items-center flex-col gap-8 md:flex-row">
        <div className="flex-1 mb-8 md:mb-0">
          <h1 className="mb-6 text-2xl sm:text-4xl md:text-5xl text-center font-bold">
            Contact Us
          </h1>
          <p className="text-xl sm:text-2xl text-center">
            We are eager to hear from you. Whether you have a question, feedback,
            or just want to say hello, feel free to drop us a line. We promise
            to get back to you as soon as we can.
          </p>
        </div>

        <div className="flex-1">
          <img
            src={contactPhoto}
            alt="contact"
            style={{ borderBottomLeftRadius: "50%" }}
          />
        </div>
      </div>
    </div>
  );
};

export default ContactIntro;
