import { useEffect, useRef, useState } from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { IoMdMenu } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { coolLogo } from "../assets";
import { navLinks } from "../Constants";
import { HashLink } from "react-router-hash-link";

const Navbar = () => {
  const [active, setActive] = useState("Home");
  const [toggle, setToggle] = useState(false);
  const location = useLocation();
  const navRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setToggle(false);
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [navRef]);

  useEffect(() => {
    const currentPath = location.pathname;
    const currentPage = currentPath.split("/")[1];
    setActive(currentPage || "");
  }, [location]);

  return (
    <div className="" ref={navRef}>
      <div className="bg-[#13294A]">
        <div className="flex justify-center items-center p-2">
          <div className="flex items-center gap-2">
            <FaPhoneAlt style={{ color: "#3BB1B9" }} />
            <p className="text-[white] text-[18px] font-medium">
              +44 7438 712348
            </p>
          </div>
        </div>
      </div>
      <div className="container mx-auto sm:px-16 px-6">
        <nav className="w-full flex items-center justify-between navbar">
          <div className="">
            {/* <img src={coolLogo} alt="" className="w-[100px]" /> */}
            <Link to="/" style={{ width: "100%"}}>
              {/* <p className="font-bold text-2xl md:text-3xl">
                Agro <span className="text-lightBlue">Cleaning</span>
              </p> */}
              <img src={coolLogo} alt="" className="w-20 h-20 object-contain" />
            </Link>
          </div>

          <ul className="hidden md:flex justify-end md:justify-center items-center flex-1">
            {navLinks.map((nav, index) => (
              <li
                key={nav.id}
                className={`
          font-semibold
          cursor-pointer
          text-base
          ${active === nav.id ? "text-black" : "text-linkColor"}
          ${index === navLinks.length - 1 ? "mr-0" : "mr-10"}
          `}
                onClick={() => setActive(nav.title)}
              >
                <Link
                  to={`/${nav.id}`}
                  className="relative text-xl w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-lightBlue after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left"
                >
                  {nav.title}
                </Link>
              </li>
            ))}
          </ul>

          <HashLink to="/contact#contactForm">
            <button className="hidden md:flex bg-accentColor px-4 sm:px-5 md:px-6 lg:px-6 xl:px-6 py-2 sm:py-2 md:py-3 lg:py-3 xl:py-3 rounded-[10px] text-[15px] font-semibold border-none outline-none  hover:bg-lightBlue transform hover:scale-110 transition-all duration-200">
              BOOK NOW
            </button>
          </HashLink>

          <div className="md:hidden flex flex-1 justify-end items-center">
            <button onClick={() => setToggle((prev) => !prev)}>
              {toggle ? (
                <IoClose style={{ width: "40px", height: "40px" }} />
              ) : (
                <IoMdMenu style={{ width: "40px", height: "40px" }} />
              )}
            </button>

            <div
              className={`${toggle ? "flex" : "hidden"}
        flex-col p-6 footer-bg absolute top-20 right-0
        mx-4 mt-16 min-w-[140px] rounded-xl sidebar z-30 gap-8`}
            >
              <ul className="list-none flex flex-col justify-end items-start flex-1 gap-6 ">
                {navLinks.map((nav, index) => (
                  <li
                    key={nav.id}
                    className={`
          font-semibold
          cursor-pointer
          text-base
          ${active === nav.id ? "text-[#FFCF59]" : "text-white"}
          ${index === navLinks.length - 1 ? "mr-0" : "mr-10"}
          `}
                    onClick={() => setActive(nav.title)}
                  >
                    <Link to={`/${nav.id}`}>{nav.title}</Link>
                  </li>
                ))}
              </ul>
              <div className="">
                <HashLink to="/contact#contactForm">
                  <button className="bg-accentColor px-4 py-2 sm:px-6 sm:py-3 rounded-[10px] text-sm sm:text-base md:text-lg lg:text-lg xl:text-x font-semibold border-none outline-none hover:bg-lightBlue transform hover:scale-110 transition-all duration-200">
                    BOOK NOW
                  </button>
                </HashLink>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
