import { Helmet } from "react-helmet";
import { AboutHero, IntroAbout } from "../components";

export default function About() {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>About us</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <AboutHero />
      <IntroAbout />
    </div>
  )
}
