import { HashLink } from "react-router-hash-link";

export default function Hero() {
  return (
    <div className="relative">
      <div className="bg-opacity-60 absolute inset-0 bg-black"></div>
      <div className="flex items-start md:items-center justify-center bg-[url('./assets/cleaning-background.jpg')] bg-cover bg-center">
        <div className="my-20 w-full md:w-3/4 lg:w-1/2 flex justify-between items-center flex-col z-10">
          <h1 className="mb-6 text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl text-center text-white font-bold">
            Agro cleaning service
          </h1>
          <p className="text-xl sm:text-2xl xl:text-2xl md:text-xl lg:text-xl text-white text-center">
            Welcome to Agro Cleaning Service! We are dedicated to providing you
            with the highest quality cleaning solutions for your home or office.
            Start your journey towards a cleaner space today!
          </p>

          <div className="mt-8">
            <HashLink to="/contact#contactForm">
              <button className="bg-accentColor hover:bg-lightBlue cursor-pointer transform hover:scale-110 px-4 sm:px-5 md:px-6 lg:px-6 xl:px-6 py-2 sm:py-2 md:py-3 lg:py-3 xl:py-3 rounded-[10px] text-sm sm:text-base md:text-lg lg:text-lg xl:text-xl font-semibold border-none outline-none transition-all duration-200">
                GET YOUR SERVICE 🌟
              </button>
            </HashLink>
          </div>
        </div>
      </div>
    </div>
  );
}
