import { Helmet } from "react-helmet";
import {
  AboutUs,
  ContactUs,
  Hero,
  Services,
  Testimonials,
} from "../components";

export default function Home() {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>AgroCleaning</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <Hero />
      <AboutUs />
      <Services />
      <Testimonials />
      <ContactUs showHeading={true} />
    </div>
  );
}
