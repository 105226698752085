import { Helmet } from "react-helmet";
import { ServiceHero, ServicesList } from "../components";

export default function Services() {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Services</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <ServiceHero />
      <ServicesList />
    </div>
  )
}
