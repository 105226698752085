import { IoLocationSharp } from "react-icons/io5";
import { MdOutlineEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import emailjs from "@emailjs/browser";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BeatLoader } from "react-spinners";

const ContactUs = ({ showHeading = true }) => {
  // const [formSubmitted, setFormSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setIsLoading(true);

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        e.target,
        process.env.REACT_APP_USER_ID
      )
      .then(
        (result) => {
          console.log(result.text);
          toast.success("Message sent successfully!");
          e.target.reset();
          setIsSubmitting(false);
          setIsLoading(false);
        },
        (error) => {
          console.log(error.text);
          toast.error("Error sending email.");
          setIsSubmitting(false);
          setIsLoading(false);
        }
      );
  };

  return (
    <div className="mt-32 py-16 bg-[#F5F5F5]">
      <ToastContainer />
      <div className="container mx-auto sm:px-16 px-6">
        {showHeading && (
          <div className="flex flex-col items-center mb-8">
            <h2 className="text-lightBlue text-3xl sm:text-4xl mb-2 font-bold">
              Contact
              <span className="text-black text-3xl sm:text-4xl mb-2 font-normal">
                {" "}
                Us
              </span>
            </h2>
            <p className="text-[#717171] text-lg sm:text-xl font-normal">
              Any question or remarks? Just write us a message!
            </p>
          </div>
        )}

        {/* box */}
        <div className="flex flex-col lg:flex-row gap-4 bg-white">
          <div className="newGradient bg-cover px-6 py-12 rounded-lg w-full lg:w-[40%] md:text-center lg:text-start">
            <div className="mb-8">
              <h4 className="text-white text-2xl sm:text-3xl font-semibold mb-4">
                Contact Information
              </h4>
              <p className="text-white text-lg sm:text-xl font-normal">
                Fill the form and we will get back to you
              </p>
            </div>
            <div className="flex items-center gap-4 mb-6 mt-20 lg:mt-32">
              <FaPhoneAlt style={{ fontSize: "24px", color: "white" }} />
              <p className="text-white text-lg sm:text-xl font-normal">
                Tel. +44 7438 712348
              </p>
            </div>
            <div className="flex items-center gap-4 mb-6">
              <MdOutlineEmail style={{ fontSize: "24px", color: "white" }} />
              <p className="text-white text-lg sm:text-xl font-normal">
                agrocleaning811@gmail.com
              </p>
            </div>
            <div className="flex items-center gap-4 mb-6">
              <IoLocationSharp style={{ fontSize: "24px", color: "white" }} />
              <p className="text-white text-lg sm:text-xl font-normal">
                OL16 Newhey Road Milnrow <br />
                Rochdale Manchester
              </p>
            </div>
          </div>

          <div className="mt-8 p-6 w-full lg:w-[60%]">
            <form action="" className="w-full" onSubmit={sendEmail}>
              <div className="flex flex-col md:flex-row gap-4 mb-8">
                <div className="flex flex-col w-full">
                  <label htmlFor="name" className="mb-4">
                    First Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="fname"
                    placeholder="John"
                    className="w-full px-4 py-2 border border-linkColor rounded-[3px] outline-0"
                  ></input>
                </div>

                <div className="flex flex-col w-full">
                  <label htmlFor="name" className="mb-4">
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="lname"
                    name="lname"
                    className="w-full px-4 py-2 border border-linkColor rounded-[3px] outline-0"
                    placeholder="Doe"
                  ></input>
                </div>
              </div>

              <div className="flex flex-col md:flex-row gap-4 mb-8">
                <div className="flex flex-col w-full">
                  <label htmlFor="name" className="mb-4">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="px-4 py-2 border border-linkColor rounded-[3px] outline-0"
                    placeholder="example@gmail.com"
                  ></input>
                </div>

                <div className="flex flex-col w-full">
                  <label htmlFor="name" className="mb-4">
                    Phone Number
                  </label>
                  <input
                    type="phone"
                    name="phone"
                    id="name"
                    className="px-4 py-2 border border-linkColor rounded-[3px] outline-0"
                    placeholder="+44 1234 567890"
                  ></input>
                </div>
              </div>

              <div>
                <div className="flex flex-col my-4 w-full">
                  <label
                    htmlFor=""
                    className="text-black text-[15px] font-bold leading-[20px] mb-2"
                  >
                    Message
                  </label>
                  <textarea
                    cols="10"
                    rows="5"
                    name="message"
                    placeholder="Write your message"
                    className="p-4 border-b border-[#8D8D8D] rounded-[3px] outline-0"
                  ></textarea>
                </div>
              </div>

              <div className="flex items-center justify-end mt-6">
                <button
                  disabled={isSubmitting || isLoading}
                  className={`text-[white] font-medium text-lg md:text-xl button-contact newGradient px-4 p-2 md:px-8 md:py-4 mb-6 transform transition-all duration-200 cursor-pointer ${
                    isSubmitting
                      ? "opacity-50"
                      : "hover:bg-lightBlue hover:scale-110"
                  }`}
                >
                  {isLoading ? <BeatLoader color="#ffffff" /> : "Send Message"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
