import { testimonials } from "../../Constants";
import { quoteIcon } from "../../assets";

const Testimonials = () => {
  return (
    <div className="my-16 lg:my-32 container mx-auto sm:px-16 px-6">
      <div className="flex flex-col items-center mb-4">
        <h2 className="text-center text-2xl sm:text-3xl md:text-4xl font-bold mb-6 text-lightBlue">
          What 
          <span className="text-center text-2xl sm:text-3xl md:text-4xl font-normal text-black"> our clients say</span>
        </h2>
        <p className="text-[#333] text-[18px] font-normal leading-[26px] max-w-2xl text-center">
          We are proud to share the testimonials from our clients who have
          experienced our exceptional cleaning services.
        </p>
      </div>

      {/* testimonials box */}
      <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {testimonials.map((review) => (
          <div className="p-12 newGradient relative bg-cover rounded-[26px] mt-14" key={review.id}>
            <p className="text-white text-center text-[18px] font-normal leading-[28px]">
              {review.content}
            </p>
            <img
              src={review.img}
              alt="testimonial"
              className="absolute w-[108px] h-[108px] object-cover rounded-full transform -translate-x-1/2 left-1/2"
            />
            <img
              src={quoteIcon}
              alt="quote icon"
              className="absolute top-[-5%] left-[3%] w-[40px] h-[40px]"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
