import { useState } from "react";
import { frequentlyAsked } from "../../Constants";
import { Link } from "react-router-dom";

export default function FaqSection() {
  const [openIndex, setOpenIndex] = useState(null);

  const handleClick = (index) => {
    if (openIndex === index) {
      setOpenIndex(null);
    } else {
      setOpenIndex(index);
    }
  };

  return (
    <section className="py-10 bg-gray-50 sm:py-16 lg:py-24">
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="max-w-2xl mx-auto text-center">
          <h2 className="text-2xl font-bold leading-tight text-black sm:text-3xl lg:text-4xl">
            Frequently Asked <span className="text-lightBlue">Questions</span>
          </h2>
          <p className="max-w-xl mx-auto mt-4 text-base leading-relaxed text-gray-600">
            Have questions? Here are some common responses that might be helpful.
          </p>
        </div>

        <div className="max-w-3xl mx-auto mt-8 space-y-4 md:mt-16">
          {frequentlyAsked.map((ask, index) => (
            <div
              key={ask.id}
              className="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50 border-l-lightBlue border-l-4"
            >
              <button
                type="button"
                className="flex items-center justify-between w-full px-4 py-5 sm:p-6"
                onClick={() => handleClick(index)}
              >
                <span className="flex text-lg font-semibold text-black">
                  {ask.question}
                </span>

                <svg
                  className={`w-6 h-6 text-gray-400 ${openIndex === index ? 'rotate-180' : ''}`}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="#3BB1B9"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>

              {openIndex === index && (
                <div className="px-4 pb-5 sm:px-6 sm:pb-6">
                  <p>{ask.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>

        <p className="text-center text-gray-600 textbase mt-9">
          Did not find the answer you are looking for?{" "}
          <Link to="/contact" className="faqLink"> Contact our support</Link>
        </p>
      </div>
    </section>
  );
}
