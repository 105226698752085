import { Helmet } from "react-helmet";
import { FaqSection } from "../components";

export default function FAQ() {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>FAQ</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <FaqSection />
    </div>
  )
}
