import { Navigation, Pagination, A11y, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { services } from "../../Constants";
import { HashLink } from "react-router-hash-link";

export default function Services() {
  return (
    <div className="bg-[#F5F5F5] my-16 lg:my-32 py-10 lg:py-14">
      <div className="container mx-auto sm:px-16 px-6">
        <div className="mt-8">
          <h2 className="text-center text-2xl sm:text-3xl md:text-4xl font-bold mb-8 text-lightBlue">
            Our
            <span className="text-center text-2xl sm:text-3xl md:text-4xl font-normal text-black">
              {" "}
              Services
            </span>
          </h2>
        </div>

        <Swiper
          modules={[Navigation, Pagination, A11y, Autoplay]}
          spaceBetween={50}
          navigation
          pagination={{ clickable: true }}
          autoplay={{ delay: 2000 }}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log("slide change")}
          breakpoints={{
            0: { slidesPerView: 1 },
            768: {
              slidesPerView: 2,
              spaceBetween: 15,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 15,
            },
          }}
        >
          {services.map((service) => (
            <SwiperSlide
              key={service.id}
              className="bg-white border border-1 flex flex-col items-center"
            >
              {/* <img
                src={service.img}
                alt="service"
                style={{ height: "200px", objectFit: "cover" }}
              /> */}
              <img
                src={service.img}
                alt="service"
                style={{ height: "200px", objectFit: "cover" }}
              />

              <div className="p-4">
                <h5 className="text-[#1C1C1C] text-lg md:text-xl font-semibold text-center">
                  {service.title}
                </h5>
                <p className="text-center mb-6">{service.content}</p>
              </div>
              <div className="flex items-center justify-center">
                <HashLink to="/contact#contactForm">
                  <button className="mb-6 text-[#1C1C1C] font-semibold bg-[#FFCF59] rounded-[13px] text-base md:text-lg px-4 p-2 md:px-6 md:py-3 hover:bg-lightBlue transform hover:scale-110 transition-all duration-200 cursor-pointer">
                    GET STARTED
                  </button>
                </HashLink>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}
