import { carpetCleaning, housekeeping, officeCleaning, officeManager, pestControl, postBuild, postEvent, secondManager, sofasetCleaning, thirdTestimony } from "../assets";

export const services = [
    {
      id: 1,
      content:
        "Our office cleaning services ensure a spotless and hygienic workspace, promoting a healthy and productive environment for employees.",
        title: "Office Cleaning Services",
      img: officeCleaning,
    },
    {
      id: 2,
      content:
        "We offer professional sofa set cleaning services to remove dirt, stains, and allergens from your furniture, leaving it fresh and revitalized",
      title: "Sofa Set Cleaning",
      img: sofasetCleaning,
    },
    {
      id: 3,
      content:
        "Our carpet cleaning services include deep cleaning and stain removal, ensuring your carpets are clean, fresh, and free from allergens and bacteria.",
      title: "Carpet Cleaning Services",
      img: carpetCleaning,
    },
    {
      id: 4,
      content:
        "Comprehensive housekeeping services to keep your home clean and organized. Our team handles routine cleaning and laundry",
      title: "Housekeeping Services",
      img: housekeeping,
    },
    {
      id: 5,
      content:
        "After construction or renovation, we provide thorough cleaning services to remove debris, dust, and construction residues.",
      title: "Post Construction Cleaning",
      img: postBuild,
    },
    {
        id: 6,
        content:
          "Effective pest control solutions to eliminate and prevent infestations, ensuring a safe and pest-free environment for your home or office.",
        title: "Pest Control Services",
        img: pestControl,
      },
    {
        id: 7,
        content:
          "We offer post-event cleaning services to quickly and efficiently clean up after events, leaving the venue spotless and ready for its next use.",
        title: "Post Event Cleaning",
        img: postEvent,
      },
  ];


  export const testimonials = [
    {
      id: 1,
      content:
        "Absolutely thrilled with the cleaning service! The team arrived on time and got straight to work. Every corner of my home has been touched with their magic and it has never been cleaner. Their attention to detail is commendable.",      
        img: thirdTestimony,
    },
    {
      id: 2,
      content:
        "As a manager of a busy office, I was looking for a reliable cleaning service that could maintain a pristine environment for my team. I am beyond pleased with the services provided! The cleaning crew is punctual, thorough, and incredibly efficient.",
      img: officeManager,
    },
    {
      id: 3,
      content:
        "Hosting a large event can be stressful, but thanks to the exceptional cleaning service, I had one less thing to worry about. The team was professional, organized, and took care of every detail before, during, and after the event. ",
      img: secondManager,
    },
  ]

  export const navLinks = [
    {
      id: "",
      title: "Home",
    },
    {
      id: "about",
      title: "About",
    },
    {
      id: "services",
      title: "Services",
    },
    {
      id: "faq",
      title: "FAQ",
    },
    {
      id: "contact",
      title: "Contact",
    },
  ];

  export const frequentlyAsked = [
    {
      id: "1",
      question: "How do I book a service?",
      answer: "You can book by messaging us through WhatsApp or filling the contact form on our website"
    },
    {
      id: "2",
      question: "How long does the cleanup take?",
      answer: "We strive to give our customers the best service, so we get started right away and focus on cleaning all areas. We also keep in mind not to take too long",
    },
    {
      id: "3",
      question: "Are you available 24 hours?",
      answer: "Yes we are available 24 hours and we arrive an hour after the booking is confirmed",
    },
    {
      id: "4",
      question: "Can I negotiate my payment plan?",
      answer: "Yes you can negotiate your booking plan to ensure you leave satisfied with our service",
    },
  ];
  
 